import React from 'react'
import styled, { css } from 'styled-components/macro'

import config from '../config'

export const styles = css`
  font-family: inherit;
  border: 0;
  font-size: inherit;
  padding: 0.75rem;
  background: #f7f7f7;
  outline: none;
  border: 1px solid transparent;
  transition: border 0.25s ease;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;

  &[type='date'] {
    height: 42px;

    &:invalid::-webkit-datetime-edit {
      color: red;
    }
  }

  &:focus {
    border-color: ${config.colors.secondary};
  }

  &::placeholder,
  &::-webkit-datetime-edit-text, /* this makes the slashes in dd/mm/yyyy grey */
  &::-webkit-datetime-edit-day-field[aria-valuetext=blank],
  &::-webkit-datetime-edit-month-field[aria-valuetext=blank],
  &::-webkit-datetime-edit-year-field[aria-valuetext=blank] {
    color: #ccc;
  }

  &:read-only {
    cursor: text;
    border-color: transparent;
  }
`

const StyledInput = styled.input`
  ${styles}
`

function Input(props: any) {
  return <StyledInput type="text" {...props} />
}

export default Input
