import jwt from 'jsonwebtoken'

import config from '../config'

export function isLoggedIn() {
  const status = localStorage.getItem(config.token.key) !== null
  return status
}

export function isAuth() {
  return isLoggedIn()
}

function decodeToken(): any {
  const token = localStorage.getItem(config.token.key)
  if (token) {
    return jwt.decode(token)
  }
  return null
}

export function getFirstname() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.firstname
}

export function getId() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.id
}

export function getFullname() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return `${payload.firstname} ${payload.lastname}`
}

export function getAddress() {
  const payload = decodeToken()

  if (!payload) {
    return ''
  }

  return payload.shippingAddress
}

export function getToken() {
  return localStorage.getItem(config.token.key)
}
