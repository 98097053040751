import React from 'react'

import Error from './Error'
import ErrorProvider from './ErrorProvider'

function ErrorModule({ rmaId, errorCode, onUpdate }: any) {
  return (
    <ErrorProvider rmaId={rmaId}>
      {({ errors }) => (
        <Error errorCode={errorCode} errors={errors} onUpdate={onUpdate} />
      )}
    </ErrorProvider>
  )
}

export default ErrorModule
