import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faFileExport } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import Logo from './Logo'
import Container from 'ui/Container'
import UsersFilter from './Users/UsersFilter/UsersFilter'
import Search from './Board/Search'
import * as auth from '../utils/auth'

interface IHeader {
  search: string
  userFilter: string[]
  onSearchChange(search: string): void
  onUserFilterToggle(id: string): void
}

const Wrapper = styled.header`
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  justify-content: center;
  height: 74px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
`

const Placeholder = styled.div`
  height: 74px;
`

const Main = styled.div`
  width: 200px;
`

const Center = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Meta = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: inherit;
  }
`

const Spacer = styled.span`
  display: inline-block;
  margin: 0 8px;
`

const LogoutLink = styled(Link)`
  color: inherit;
  position: relative;
  top: 1px;
`

const Export = styled.a`
  font-size: 16px;
  padding: 4px 0;
  margin: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
`

function Header({
  search,
  onSearchChange,
  userFilter,
  onUserFilterToggle,
}: IHeader) {
  const token = auth.getToken()

  return (
    <>
      <Wrapper>
        <Main>
          <Link to="/">
            <Logo />
          </Link>
        </Main>
        <Center>
          <Search search={search} onSearchChange={onSearchChange} />
          <UsersFilter
            onUserFilterToggle={onUserFilterToggle}
            userFilter={userFilter}
          />
          <Export
            href={`${process.env.REACT_APP_API_ENDPOINT}/rma/export?token=${token}`}
            target="_blank"
          >
            <FontAwesomeIcon icon={faFileExport} />
          </Export>
        </Center>
        <Meta>
          {auth.getFirstname()} <Spacer>|</Spacer>
          <LogoutLink to="/auth/logout">
            <FontAwesomeIcon icon={faSignOut} />
          </LogoutLink>
        </Meta>
      </Wrapper>
      <Placeholder />
    </>
  )
}

export default Header
