export default {
  EKYPRO0005: 'Sensor abgenutzt',
  EKYPRO0008: 'Aktualisierung Gerät',
  EKYPRO0010: 'Anzeige SE defekt',
  EKYPRO0016: 'Sensorbruch',
  EKYPRO0019: 'Steckverbinder zu Tastenfeld defekt',
  EKYPRO0023: 'Conv. defekt/falsche Version',
  EKYPRO0042: 'E2 Codesperre',
  EKYPRO0057: 'Flüssigkeitseintritt über Sensor',
  EKYPRO0061: 'FSM sendet kein Signal',
  EKYPRO0062: 'Batterien leer',
  EKYPRO0064: 'FZ-FSM nicht verheiratet',
  EKYPRO0065: 'FZ surrt aber reagiert nicht',
  EKYPRO0066: 'Gehäuse defekt',
  EKYPRO0079: 'Kein Befund',
  EKYPRO0080: 'Verkabelung nicht OK',
  EKYPRO0081: 'Kabelbruch',
  EKYPRO0082: 'Gerät iO, Fehler bei Anwender',
  EKYPRO0087: 'Federklemme defekt',
  EKYPRO0097: 'Leihgerät retour',
  EKYPRO0104: 'Kupplungsschieber def./abgen.',
  EKYPRO0108: 'Mechanik des FZ defekt',
  EKYPRO0115: 'M-Knauf falsche Version',
  EKYPRO0117: 'Netzteil defekt',
  EKYPRO0118: 'Geräte nicht miteinander gekoppelt',
  EKYPRO0126: 'NU-Fehler',
  EKYPRO0133: 'Platine defekt-Fehlanschluss',
  EKYPRO0134: 'Platine defekt - Bauteil',
  EKYPRO0136: 'Platine defekt - mech.Beschädigung',
  EKYPRO0144: 'Jumper Relais falsch gesetzt',
  EKYPRO0145: 'Mech. Relais defekt',
  EKYPRO0158: 'Sensor defekt',
  EKYPRO0161: 'Sensorfläche beschädigt',
  EKYPRO0163: 'Sonneneinstrahlung',
  EKYPRO0164: 'Sensor verschmutzt',
  EKYPRO0168: 'Sicherung defekt',
  EKYPRO0170: 'Geräte passen nicht zusammen',
  EKYPRO0173: 'Softwarefehler',
  EKYPRO0175: 'Taste/Schalter defekt',
  EKYPRO0184: 'Versorgungsspannung defekt',
  EKYPRO0189: 'Verbindung zum Sensor defekt',
  EKYPRO0196: 'Zylinder defekt',
  EKYPRO0198: 'Flüssigkeitseintritt über Gehäuse',
  EKYPRO0199: 'Fehlbestellung',
  EKYPRO0200: 'Ware nicht benötigt',
  EKYPRO0201: 'Falschlieferung',
  EKYPRO0202: 'Doppellieferung',
  EKYPRO0203: 'Paketdienst',
  EKYPRO0204: 'Unbekannter Grund, Gutschrift',
  EKYPRO0205: 'Lagerbereinigung Neuware',
  EKYPRO0206: 'Lagerbereinigung Gebrauchtware',
  EKYPRO0207: 'DRAM Fehler',
  EKYPRO0208: 'Halbleiterrelais defekt',
  EKYPRO0209: 'Platine defekt - Überspannung',
  EKYPRO0210: 'Status-LED defekt',
  EKYPRO0211: 'Adminfinger unbekannt',
  EKYPRO0212: 'Platine defekt - Lötstelle',
  EKYPRO0213: 'Prozessorsysteme',
  EKYPRO0214: 'FZ erkennt Karte nicht',
  EKYPRO0232: 'Fingerspeicher verloren',
  EKYPRO0233: 'FAR-Fingerverwechslung',
  EKYPRO0234: 'Rückholaktion',
  EKYPRO0235: 'Gutschrift abgelehnt',
}
