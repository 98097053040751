import gql from 'graphql-tag'

import { rmaFields } from './rmaFields'

export const addRmaMutation = gql`
  mutation addRma($rma: RmaInput!) {
    addRma(rma: $rma) {
      ...RmaFields
    }
  }
  ${rmaFields}
`
