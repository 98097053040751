import React from 'react'
import styled, { css } from 'styled-components/macro'

import { getStyles } from '../utils/styles'
import logoImg from '../assets/logo.svg'

interface IImg {
  size?: any
}

const sizes: any = {
  small: css`
    height: 1.825rem;
  `,
  medium: css`
    height: 2.5rem;
  `,
  large: css`
    height: 3.5rem;
  `,
}

const Img = styled.img<IImg>`
  display: block;
  width: auto;
  ${props => getStyles(props.size, sizes)}
`

function Logo({ size = 'medium', ...props }) {
  return <Img src={logoImg} alt="ekey Logo" size={size} {...props} />
}

export default Logo
