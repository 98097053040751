import gql from 'graphql-tag'

import { fileFields } from './fileFields'

export const addRmaFileMutation = gql`
  mutation addRmaFile($rmaId: ID!, $file: Upload!) {
    addRmaFile(rmaId: $rmaId, file: $file) {
      ...FileFields
    }
  }
  ${fileFields}
`
