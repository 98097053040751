import styled, { css } from 'styled-components/macro'

import config from '../config'

interface IProps {
  size?: number
}

const Title = styled.h1<IProps>`
  margin: 0 0 3rem 0;
  padding: 0;
  /* font-size: 42px; */
  line-height: 1.1;
  /* font-weight: 500; */
  /* color: ${config.colors.primary}; */

  font-size: 22px;
  margin: 0;
  padding: 0;

  ${props =>
    props.size === 2 &&
    css`
      font-size: 22px;
      margin-bottom: 1.25rem;
      margin-bottom: 1.5rem;
    `}
`

export default Title
