import { useQuery } from '@apollo/react-hooks'

import { getCustomersQuery } from './graphql/getCustomersQuery'

interface IProps {
  children(props: any): JSX.Element
}

function CustomerProvider({ children }: IProps) {
  const { data, error } = useQuery(getCustomersQuery)

  if (error) {
    console.log(error)
    return null
  }

  if (!data) {
    return null
  }

  const customers = data.getCustomers

  return children({ customers })
}

export default CustomerProvider
