import styled, { css } from 'styled-components/macro'

import config from '../config'

interface IProps {
  size?: string
}

const ButtonPrimary: any = styled.button<IProps>`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${config.colors.primary};
  border: none;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  ${props =>
    props.size === 'small' &&
    css`
      width: 48px;
      height: 48px;
    `}

  ${props =>
    props.color === 'secondary' &&
    css`
      background: #f1f1f1;
      color: #222;
    `}
`

export default ButtonPrimary
