import React, { useState } from 'react'
import styled from 'styled-components/macro'

import * as date from '../../../utils/date'
import Avatar from '../../Users/Avatar'
import ButtonLink from 'ui/ButtonLink'
import Input from 'ui/Input'
import { IComment } from './CommentsProvider'

interface ICommentComponent {
  comment: IComment
  onUpdate(id: string, content: string): void
  onDelete(id: string): void
}

const CommentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`

const Main = styled.div`
  flex: 1;
`

const Header = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.4;
  margin-bottom: 0.25rem;
`

const User = styled.div``

const Date = styled.div`
  margin-left: auto;
  font-weight: 400;
`

const Content = styled.div``

const Actions = styled.div`
  font-size: 11px;
  margin-top: 4px;
`

function Comment({ comment, onDelete, onUpdate }: ICommentComponent) {
  const [isEditing, setIsEditing] = useState(false)
  const [content, setContent] = useState(comment.content)

  function _onDelete() {
    if (window.confirm('Möchtest du den Kommentar wirklich löschen?')) {
      onDelete(comment.id)
    }
  }

  function _onChange(e) {
    const value = e.target.value
    console.log('DEBUG: _onChange:', value)
    setContent(value)
  }

  function _onUpdate() {
    onUpdate(comment.id, content)
    setIsEditing(false)
    setContent('')
  }

  function _onEdit() {
    setIsEditing(true)
  }

  function _onCancel() {
    setIsEditing(false)
  }

  return (
    <CommentWrapper>
      <StyledAvatar user={comment.user} size="large" />
      <Main>
        <Header>
          <User>
            {comment.user.firstname} {comment.user.lastname}
          </User>
          <Date>{date.formatDatetime(parseInt(comment.creationDate))} Uhr</Date>
        </Header>
        <Content>
          {isEditing ? (
            <>
              <Input value={content} onChange={_onChange} />
            </>
          ) : (
            <>{comment.content}</>
          )}
        </Content>
        {isEditing ? (
          <>
            <Actions>
              <ButtonLink size="small" onClick={_onCancel}>
                Abbrechen
              </ButtonLink>{' '}
              |{' '}
              <ButtonLink size="small" onClick={_onUpdate}>
                Speichern
              </ButtonLink>
            </Actions>
          </>
        ) : (
          <>
            {comment.canEdit && (
              <Actions>
                <ButtonLink size="small" onClick={_onEdit}>
                  Bearbeiten
                </ButtonLink>{' '}
                |{' '}
                <ButtonLink size="small" onClick={_onDelete}>
                  Löschen
                </ButtonLink>
              </Actions>
            )}
          </>
        )}
      </Main>
    </CommentWrapper>
  )
}

export default Comment
