import React from 'react'
import { Route } from 'react-router-dom'

import AuthLogin from './AuthLogin'
import AuthLogout from './AuthLogout'

export function AuthModule() {
  return (
    <>
      <Route path="/auth/login">
        <AuthLogin />
      </Route>
      <Route path="/auth/logout">
        <AuthLogout />
      </Route>
    </>
  )
}
