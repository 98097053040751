import { useMutation } from '@apollo/react-hooks'

import { deleteRmaMutation, getRmasQuery } from './graphql'

/** Provides RMA related actions. */
export function useRmaProvider() {
  const [deleteRma] = useMutation(deleteRmaMutation)

  /** Provides the mutation to remove an RMA by ID. */
  async function onDelete(id) {
    return deleteRma({
      variables: { id },
      refetchQueries: [{ query: getRmasQuery }],
    })
  }

  return { onDelete }
}
