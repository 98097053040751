import gql from 'graphql-tag'

export const moveRmaMutation = gql`
  mutation moveRma($moveRmaInput: MoveRmaInput!) {
    moveRma(moveRmaInput: $moveRmaInput) {
      id
      status
      statusCountryCode
      position
    }
  }
`
