import { format as dateFormat, isBefore } from 'date-fns'

export function format(timestamp, format = 'dd.MM.yyyy') {
  // Timestamp from database is in milliseconds.
  return dateFormat(new Date(timestamp * 1), format)
}

export function formatDate(timestamp, format = 'dd.MM.yyyy') {
  if (!timestamp) {
    return null
  }

  return dateFormat(new Date(timestamp * 1), format)
}

export function formatDatetime(timestamp, format = 'dd.MM.yyyy HH:mm') {
  return dateFormat(new Date(timestamp * 1), format)
}

export function isOverdue(timestamp) {
  return isBefore(new Date(timestamp * 1), new Date())
}
