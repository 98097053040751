import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import RmaPreview from '../Rmas/RmaPreview'
import ButtonPrimary from 'ui/ButtonPrimary'

const Wrapper = styled.div`
  background: #f7f7f7;
  margin: 0 0.75rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Header = styled.div`
  margin: 24px 0;
  height: 48px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
`

const Title = styled.h1`
  font-size: 22px;
  margin: 0;
  padding: 0;
`

const Count = styled.span`
  font-size: 15px;
  opacity: 0.4;
`

const Previews = styled('div')<any>`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  min-height: 200px;
  flex: 1;
  transition: background-color 0.25s ease;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  height: auto;

  ${(props) =>
    props.isOver &&
    css`
      background: #e4e4e4;
    `}

  ${(props) =>
    props.split &&
    css`
      max-height: 50vh;
    `}

  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 10px;
    width: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 10px;
  }
`

const Preview: any = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0.75rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
  margin-bottom: 1rem;
`

const AddButton = styled.div`
  margin-left: auto;
  font-size: 18px;
`

function render({ title, rmas, showAddButton, split = false }) {
  return (
    <>
      <Header>
        <Title>
          {title} <Count>({rmas.length})</Count>
        </Title>
        {showAddButton && (
          <AddButton>
            <Link to="/rmas/add">
              <ButtonPrimary size="small">
                <FontAwesomeIcon icon={faPlus} />
              </ButtonPrimary>
            </Link>
          </AddButton>
        )}
      </Header>
      <Droppable droppableId={title}>
        {(provided, snapshot) => (
          <Previews
            {...provided.droppableProps}
            ref={provided.innerRef}
            isOver={snapshot.isDraggingOver}
            split={split}
          >
            {rmas.length > 0 &&
              rmas.map((rma: any, index) => (
                <Draggable draggableId={rma.id} index={index} key={rma.id}>
                  {(provided) => (
                    <Preview
                      key={rma.id}
                      to={`/rmas/${rma.id}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <RmaPreview rma={rma} />
                    </Preview>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Previews>
        )}
      </Droppable>
    </>
  )
}

function Column({
  title = '',
  rmas = [],
  showAddButton = false,
  split = false,
}) {
  // `split` splits the "In Arbeit" column into "In Arbeit (CH)" and "In Arbeit (AT)".
  if (split) {
    const rmasAT = rmas.filter((rma: any) => rma.statusCountryCode === 'AT')
    const rmasCH = rmas.filter((rma: any) => rma.statusCountryCode === 'CH')

    const titleAT = `${title} (AT)`
    const titleCH = `${title} (CH)`

    return (
      <Wrapper>
        {render({ title: titleCH, rmas: rmasCH, showAddButton, split: true })}
        {render({ title: titleAT, rmas: rmasAT, showAddButton, split: true })}
      </Wrapper>
    )
  }

  return (
    <Wrapper>{render({ title, rmas, showAddButton, split: false })}</Wrapper>
  )
}

export default Column
