import React from 'react'
import styled from 'styled-components/macro'

interface IIntro {
  children?: React.ReactNode
  meta?: React.ReactNode
}
const Wrapper = styled.div`
  display: flex;
  margin-top: 3rem;
`

const Meta = styled.div`
  margin-left: auto;
`

function Intro({ children, meta }: IIntro) {
  return (
    <Wrapper>
      {children}
      {meta && <Meta>{meta}</Meta>}
    </Wrapper>
  )
}

export default Intro
