import gql from 'graphql-tag'

export const changeRmaEventMutation = gql`
  mutation changeRmaEvent($event: RmaEventInput!) {
    changeRmaEvent(event: $event) {
      id
      microsoftEventDate
    }
  }
`
