import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'

import Intro from '../Intro'
import Title from 'ui/Title'
import Comments from './Comments'
import Customer from './Customer'
import User from './User'
import Label from 'ui/Label'
import Files from './Files'
import Error from './Error'
import Textarea from 'ui/Textarea'
import Container from 'ui/Container'
import Input from 'ui/Input'
import { formatRmaId, formatArticle } from './utils'
import { formatDatetime, formatDate } from '../../utils/date'
// import { ShippingAddress } from './ShippingAddress'
import Grid, { Item as GridItem } from 'ui/Grid'
import Checkbox from 'ui/Checkbox'
import Event from './Event/Event'
import { useEvent } from './Event/useEvent'
import ButtonPrimary from 'ui/ButtonPrimary'
import { useRmaProvider } from './useRmaProvider'
import { useHistory } from 'react-router'

export const Wrapper = styled(Container)``

const Meta = styled.div`
  display: flex;
  align-items: center;
`

const MetaItem = styled.div`
  margin-right: 1rem;
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -1rem;
`

const Item = styled.div`
  padding-left: 1rem;
`

const CheckboxItem = styled.div`
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

function Rma({ rma: initialRma, onUpdate, onCustomerUpdate }) {
  const [rma, setRma] = useState({ ...initialRma })
  const { onChangeEvent } = useEvent(rma.id)
  const { onDelete } = useRmaProvider()
  const history = useHistory()

  function onChange(key, value) {
    setRma({
      ...rma,
      [key]: value,
    })
  }

  function onBlur(key, value) {
    if (initialRma[key] !== value) {
      onUpdate({
        [key]: value,
      })
    }
  }

  function onDateChange(key, value) {
    // `value` is in the form of YYYY-MM-DD, or null
    if (!value) {
      onChange(key, null)
      return
    }

    const date = new Date(value)
    onChange(key, date)
  }

  function onChangeError(error) {
    onBlur('error', error)
  }

  function onUpdateUser(id) {
    onUpdate({
      user: { id },
    })
  }

  /** Removes an RMA by ID after asking for confirmation. */
  function _onDelete() {
    if (window.confirm('Möchtest du den RMA wirklich löschen?')) {
      if (typeof onDelete === 'function') {
        onDelete(rma.id).then(() => {
          history.push('/')
        })
      }
    }
  }

  return (
    <Wrapper>
      {rma.id && (
        <Intro
          meta={
            <ButtonPrimary size="small" onClick={_onDelete} color="secondary">
              <FontAwesomeIcon icon={faTrash} />
            </ButtonPrimary>
          }
        >
          <Title>RMA {formatRmaId(rma.id)}</Title>
        </Intro>
      )}
      <Meta>
        <MetaItem>
          <User user={initialRma.user} onUpdate={onUpdateUser} />
        </MetaItem>
        {rma.creationDate && (
          <MetaItem>
            <Label>Erstelldatum</Label>
            {formatDatetime(rma.creationDate)} Uhr
          </MetaItem>
        )}
        <MetaItem>
          <Label>Termin</Label>
          <Event
            event={initialRma.microsoftEventDate}
            onChange={onChangeEvent}
          />
        </MetaItem>
      </Meta>
      <br />
      <br />
      <Grid>
        <GridItem size={3}>
          <Customer
            customer={initialRma.customer}
            onUpdate={onCustomerUpdate}
          />
        </GridItem>
        {/*
        <GridItem size={5} offset={1}>
          <ShippingAddress address={rma.shippingAddress} />
        </GridItem>
        */}
      </Grid>

      <br />
      <br />
      <Error
        rmaId={initialRma.id}
        errorCode={initialRma.error}
        onUpdate={onChangeError}
      />
      <br />
      <br />
      <Label>Fehlerbeschreibung</Label>
      <Textarea
        value={rma.description}
        onChange={e => onChange('description', e.target.value)}
        onBlur={() => onBlur('description', rma.description)}
      />
      <br />
      <br />
      <Items>
        <Item>
          <Label>Serien-Nr.</Label>
          <Input
            type="text"
            value={rma.serial || ''}
            onChange={e => onChange('serial', e.target.value)}
            onBlur={() => onBlur('serial', rma.serial)}
          />
        </Item>
        <Item>
          <Label>Artikel-Nr.</Label>
          <Input
            type="text"
            value={formatArticle(rma.article)}
            onChange={e => onChange('article', e.target.value)}
            onBlur={() => onBlur('article', rma.article)}
          />
        </Item>
        <Item>
          <Label>Firmware</Label>
          <Input
            type="text"
            value={rma.firmware || ''}
            onChange={e => onChange('firmware', e.target.value)}
            onBlur={() => onBlur('firmware', rma.firmware)}
          />
        </Item>
        <Item>
          <Label>Kaufdatum</Label>
          <Input
            type="date"
            value={formatDate(rma.purchaseDate, 'yyyy-MM-dd') || undefined}
            onChange={e => onDateChange('purchaseDate', e.target.value)}
            onBlur={() => onBlur('purchaseDate', rma.purchaseDate)}
          />
        </Item>
        <Item>
          <Label>Verkaufsdatum</Label>
          <Input
            type="date"
            value={formatDate(rma.saleDate, 'yyyy-MM-dd') || undefined}
            onChange={e => onDateChange('saleDate', e.target.value)}
            onBlur={() => onBlur('saleDate', rma.saleDate)}
          />
        </Item>
      </Items>
      <br />
      <br />
      <Label>Endkunden-Kommission</Label>
      <Input
        type="text"
        value={rma.reference || undefined}
        onChange={e => onChange('reference', e.target.value)}
        onBlur={() => onBlur('reference', rma.reference)}
      />
      <br />
      <br />
      <br />
      <CheckboxItem>
        <Checkbox
          label="Garantie wurde um 5 Jahre verlängert."
          value={initialRma.fiveYearWarranty}
          onClick={() =>
            onBlur('fiveYearWarranty', !initialRma.fiveYearWarranty)
          }
        />
      </CheckboxItem>
      <CheckboxItem>
        <Checkbox
          label="Kostenvoranschlag für Reparatur gewünscht."
          value={initialRma.costEstimateRequested}
          onClick={() =>
            onBlur('costEstimateRequested', !initialRma.costEstimateRequested)
          }
        />
      </CheckboxItem>

      <br />
      <br />
      <Files id={rma.id} />

      <br />
      <br />
      <Comments id={rma.id} />
      <br />
      <br />
    </Wrapper>
  )
}

export default Rma
