import errors from './errors'

// TBI: RMA error code update mutation

function ErrorProvider({ rmaId, children }) {
  function onUpdate(errorCode) {
    console.log(
      `DEBUG: update: rmaId ${rmaId} with new error code ${errorCode}`
    )
  }

  return children({ errors, onUpdate })
}

export default ErrorProvider
