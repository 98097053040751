import styled, { css } from 'styled-components'

import { getStyles } from '../utils/styles'

interface IProps {
  size?: string
  style?: string
}

const styles: any = {
  danger: css`
    color: #e74c3c;
  `,
}

const ButtonLink = styled.button<IProps>`
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
  height: auto;
  line-height: auto;
  color: #222;
  text-decoration: underline;

  ${props =>
    props.size === 'small' &&
    css`
      font-size: 13px;
      font-weight: 500;
    `}

  ${props =>
    props.style &&
    css`
      ${(props: any) => getStyles(props.style, styles)}
    `}
`

export default ButtonLink
