export function transformSelect(users) {
  const items = users.reduce((accumulator, currentValue) => {
    accumulator[
      currentValue.id
    ] = `${currentValue.firstname} ${currentValue.lastname}`
    return accumulator
  }, {})

  return items
}
