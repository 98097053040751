import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default function PrivateRoute({ isAuth, children, ...props }) {
  return isAuth ? (
    children ? (
      <Route {...props}>{children}</Route>
    ) : (
      <Route {...props} />
    )
  ) : (
    <Redirect to="/auth/login" />
  )
}
