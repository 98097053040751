import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { canAuthUserEditComment } from './utils'

export interface IComment {
  id: string
  content: string
  user: any
  creationDate: any
  canEdit?: boolean
  // TODO: Types
}

const commentFragment = gql`
  fragment CommentFields on Comment {
    id
    content
    user {
      id
      firstname
      lastname
    }
    creationDate
  }
`

const getCommentsQuery = gql`
  query getComments($rmaId: ID) {
    getComments(rmaId: $rmaId) {
      ...CommentFields
    }
  }
  ${commentFragment}
`

const addCommentMutation = gql`
  mutation addComment($comment: CommentInput!) {
    addComment(comment: $comment) {
      ...CommentFields
    }
  }
  ${commentFragment}
`

const updateCommentMutation = gql`
  mutation updateComment($id: ID!, $content: String!) {
    updateComment(id: $id, content: $content) {
      ...CommentFields
    }
  }
  ${commentFragment}
`

const deleteCommentMutation = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      ...CommentFields
    }
  }
  ${commentFragment}
`

function CommentsProvider({ id, children }) {
  const { data, loading, error } = useQuery(getCommentsQuery, {
    variables: { rmaId: id },
  })
  const [addComment] = useMutation(addCommentMutation)
  const [updateComment] = useMutation(updateCommentMutation)
  const [deleteComment] = useMutation(deleteCommentMutation)

  if (loading) {
    return null
  }

  if (error) {
    return null
  }

  // Extend comment to decide whether comment is editable.
  // TODO: Add as @client resolver to GraphQL
  const comments = !id
    ? []
    : data.getComments.map(comment => {
        return {
          ...comment,
          canEdit: canAuthUserEditComment(comment.user),
        }
      })

  function onAdd(content) {
    addComment({
      variables: { comment: { rmaId: id, content } },
      refetchQueries: [{ query: getCommentsQuery, variables: { rmaId: id } }],
    })
  }

  function onUpdate(commentId, content) {
    updateComment({
      variables: { id: commentId, content },
      refetchQueries: [{ query: getCommentsQuery, variables: { rmaId: id } }],
    })
  }

  function onDelete(commentId) {
    deleteComment({
      variables: { id: commentId },
      refetchQueries: [{ query: getCommentsQuery, variables: { rmaId: id } }],
      // awaitRefetchQueries: true,
    })
  }

  return children({ comments, onAdd, onUpdate, onDelete })
}

export default CommentsProvider
