import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import Rma from './Rma'
import Intro from '../Intro'
import Title from 'ui/Title'
import Container from 'ui/Container'

import {
  deleteRmaMutation,
  updateRmaMutation,
  getRmasQuery,
  getRmaQuery,
} from './graphql'

const Section = styled.div``

/** `RmaAdd` creates an empty RMA to use/update/fill out. If nothing is updated, the RMA will be deleted again. */
function RmaAdd({ rma }) {
  // TODO: What if the comments component updates without changes untouched?
  window.localStorage.setItem('untouched', 'true')
  const history = useHistory()

  const [deleteRma] = useMutation(deleteRmaMutation)
  const [updateRma] = useMutation(updateRmaMutation)

  function onUpdate(rmaPartial) {
    window.localStorage.removeItem('untouched')
    rmaPartial.id = rma.id
    updateRma({
      variables: { rma: rmaPartial },
      refetchQueries: [{ query: getRmaQuery, variables: { id: rma.id } }],
    }).then(() => {
      window.localStorage.removeItem('untouched')
      history.push(`/rmas/${rma.id}`)
    })
  }

  function onCustomerUpdate(customerId) {
    window.localStorage.removeItem('untouched')
    const rmaInput = {
      id: rma.id,
      customer: {
        id: customerId,
      },
    }
    updateRma({ variables: { rma: rmaInput } }).then(() => {
      window.localStorage.removeItem('untouched')
      history.push(`/rmas/${rma.id}`)
    })
  }

  useEffect((): any => {
    return () => {
      if (window.localStorage.getItem('untouched')) {
        const result = window.confirm(
          'Möchtest du den gerade erstellten RMA löschen?'
        )
        if (result) {
          deleteRma({
            variables: { id: rma.id },
            refetchQueries: [{ query: getRmasQuery }],
          })
        }
      }
    }
  }, [deleteRma, rma])

  return (
    <div>
      <Section>
        <Container>
          <Intro>
            <Title>RMA erstellen</Title>
          </Intro>
          <br />
        </Container>
        <Rma
          rma={rma}
          onUpdate={onUpdate}
          onCustomerUpdate={onCustomerUpdate}
        />
      </Section>
    </div>
  )
}

export default RmaAdd
