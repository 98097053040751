import gql from 'graphql-tag'

export const customerFields = gql`
  fragment CustomerFields on Customer {
    id
    firstname
    lastname
    shippingAddress {
      company
      firstname
      lastname
      street
      city
      zip
      countryCode
    }
  }
`
