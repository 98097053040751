import { useMutation, useQuery } from '@apollo/react-hooks'

import { addRmaFileMutation } from './graphql/addRmaFileMutation'
import { deleteRmaFileMutation } from './graphql/deleteRmaFileMutation'
import { getRmaFilesQuery } from './graphql/getRmaFilesQuery'

import config from '../../../config'

export function useFilesProvider(rmaId) {
  const [addRmaFile] = useMutation(addRmaFileMutation)
  const [deleteRmaFile] = useMutation(deleteRmaFileMutation)
  const { data, refetch } = useQuery(getRmaFilesQuery, { variables: { rmaId } })

  function onUpload(files: FileList) {
    // addRmaFile({
    //   variables: { rmaId, file: files[0] },
    //   refetchQueries: [{ query: getRmaFilesQuery, variables: { rmaId } }],
    // })

    // TODO: TOKEN from localStorage

    const token = localStorage.getItem(config.token.key) || ''

    const formData = new FormData()
    formData.append('file', files[0])

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/rmas/${rmaId}/upload`, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: formData,
    }).then((response) => {
      console.log(response)
      // refetch getRmaFilesQuery
      refetch()
    })
  }

  function onDelete(id: string) {
    deleteRmaFile({
      variables: { id },
      refetchQueries: [{ query: getRmaFilesQuery, variables: { rmaId } }],
    })
  }

  const files = (data && data.getRmaFiles) || null

  return {
    onUpload,
    onDelete,
    files,
  }
}
