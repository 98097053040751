import styled, { css } from 'styled-components/macro'

interface IProps {
  compact?: boolean
}

export const styles = css`
  font-size: 13px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
`

const Label = styled.div<IProps>`
  ${styles}

  ${props =>
    props.compact &&
    css`
      margin-bottom: 4px;
    `}
`

export default Label
