import React from 'react'
import { createGlobalStyle } from 'styled-components/macro'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'

import 'normalize.css'

import { AuthModule } from './auth'
import { RmasModule } from './Rmas'
import * as auth from '../utils/auth'
import globalStyle from '../assets/globalStyle'
import PrivateRoute from '../utils/auth/PrivateRoute'

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`

function App() {
  const client = useApolloClient()
  const isAuth = auth.isAuth()
  client.writeData({ data: { isAuth } })

  return (
    <Router>
      <GlobalStyle />
      <Switch>
        <Route path="/auth">
          <AuthModule />
        </Route>
        <PrivateRoute path="/" isAuth={isAuth}>
          <RmasModule />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App
