import gql from 'graphql-tag'

import { customerFields } from './customerFields'

export const getCustomersQuery = gql`
  query getCustomers {
    getCustomers {
      ...CustomerFields
    }
  }
  ${customerFields}
`
