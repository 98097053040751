import gql from 'graphql-tag'

import { rmaFields } from './rmaFields'

export const getRmasQuery = gql`
  query getRmas {
    getRmas {
      ...RmaFields
    }
  }
  ${rmaFields}
`
