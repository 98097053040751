import React from 'react'

import CustomerProvider from './CustomerProvider'
import Customer from './Customer'
import { ICustomer } from './types'

interface ICustomerModuleProps {
  customer: ICustomer
  onUpdate(id: string): void
}

function CustomerModule({ customer, onUpdate }: ICustomerModuleProps) {
  function _onUpdate(customerId) {
    onUpdate(customerId)
  }

  return (
    <CustomerProvider>
      {({ customers }) => (
        <Customer
          customer={customer}
          customers={customers}
          onUpdate={_onUpdate}
        />
      )}
    </CustomerProvider>
  )
}

export default CustomerModule
