import gql from 'graphql-tag'

import { rmaFields } from './rmaFields'

export const getRmaQuery = gql`
  query getRma($id: ID!) {
    getRma(id: $id) {
      ...RmaFields
    }
  }
  ${rmaFields}
`
