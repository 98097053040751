import React from 'react'
import styled, { css } from 'styled-components/macro'

interface IUser {
  firstname: string
  lastname: string
  color?: string
}

interface IAvatar {
  user?: IUser
  size?: string
}

interface IInitials {
  size?: any
  color?: any
}

const styles = {
  small: css`
    width: 28px;
    height: 28px;
    border-radius: 14px;
  `,
  medium: css`
    width: 36px;
    height: 36px;
    border-radius: 36px;
    font-size: 11px;
  `,
  large: css`
    width: 48px;
    height: 48px;
    border-radius: 24px;
  `,
}

const Initials = styled.div<IInitials>`
  background: #979697;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  border: 3px solid #979697;
  ${props => styles[props.size]}

  ${props =>
    props.color &&
    css`
      border-color: ${props.color};
    `}
`

function Avatar({ user, size = 'medium', ...props }: IAvatar) {
  const firstnameInitial = user ? user.firstname.charAt(0) : ''
  const lastnameInitial = user ? user.lastname.charAt(0) : ''

  return (
    <Initials color={user ? user.color : null} size={size} {...props}>
      {firstnameInitial}
      {lastnameInitial}
    </Initials>
  )
}

export default Avatar
