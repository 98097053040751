import React from 'react'

import CommentsProvider from './CommentsProvider'
import Comments from './Comments'

function CommentsModule({ id }) {
  return (
    <CommentsProvider id={id}>
      {({ comments, onAdd, onUpdate, onDelete }) => (
        <Comments
          comments={comments}
          onAdd={onAdd}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
    </CommentsProvider>
  )
}

export default CommentsModule
