import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/pro-regular-svg-icons'

interface IIcon {
  isButton?: boolean
}

interface ISearch {
  search: string
  onSearchChange(search: string): void
}

let typingTimer: any
const doneTypingInterval = 300

const Wrapper = styled.div``

const InputWrapper = styled.div`
  position: relative;
`

const Input = styled.input`
  border-radius: 21px;
  background: #979697;
  height: 42px;
  width: 300px;
  max-width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  padding: 0;
  outline: none;
  padding-left: 40px;
  font-size: inherit;
  font-family: inherit;
  color: #fff;
  transition: background 0.25s;

  ::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  &:active,
  &:focus {
    background: #979697;
  }
`

const Icon = styled.div<IIcon>`
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 18px;
  color: #fff;
  height: 42px;
  width: 42px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    !props.isButton &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.isButton &&
    css`
      cursor: pointer;
    `}
`

function Search({ search, onSearchChange }: ISearch) {
  const [_search, _setSearch] = useState('')

  const isSearchActive = _search && _search !== ''

  const onReset = () => {
    _setSearch('')
    onSearchChange('')
  }

  const onKeyUp = () => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(doneTyping, doneTypingInterval)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setSearch(e.target.value)
  }

  const doneTyping = () => {
    let cleanSearch = _search
    cleanSearch.toLowerCase()
    cleanSearch.trim()
    onSearchChange(cleanSearch)
  }

  return (
    <Wrapper>
      <InputWrapper>
        {isSearchActive ? (
          <Icon onClick={onReset} isButton>
            <FontAwesomeIcon icon={faTimes} />
          </Icon>
        ) : (
          <Icon>
            <FontAwesomeIcon icon={faSearch} />
          </Icon>
        )}
        <Input
          type="text"
          placeholder="Suchen..."
          value={_search}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      </InputWrapper>
    </Wrapper>
  )
}

export default Search
