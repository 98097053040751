import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { getRmaQuery } from './graphql/getRmaQuery'
import { updateRmaMutation } from './graphql/updateRmaMutation'

function RmaProvider({ children }) {
  const { id } = useParams<any>()
  const { data, loading, error } = useQuery(getRmaQuery, { variables: { id } })
  const [updateRma] = useMutation(updateRmaMutation)

  if (loading) {
    // TODO: Provide skeleton screen
    return null
  }

  if (error) {
    console.error(error)
    return null
  }

  function onUpdate(rma) {
    rma.id = id
    updateRma({
      variables: { rma },
      refetchQueries: [{ query: getRmaQuery, variables: { id } }],
    })
  }

  function onCustomerUpdate(customerId) {
    const rmaInput = {
      id,
      customer: {
        id: customerId,
      },
    }
    updateRma({ variables: { rma: rmaInput } })
  }

  const rma = data.getRma

  return children({ rma, onUpdate, onCustomerUpdate })
}

export default RmaProvider
