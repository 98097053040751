import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { xor } from 'lodash'

import Header from '../Header'
import RmasProvider from './RmasProvider'
import Rmas from './Rmas'
import RmaProvider from './RmaProvider'
import Rma from './Rma'
import { RmaAddRoute } from './RmaAddRoute'

const initialSearchState: string = ''
const initialUserFilterState: string[] = []

export function RmasModule() {
  // TODO: Think about using Apollo Local State
  const [search, setSearch] = useState(initialSearchState)
  const [userFilter, setUserFilter] = useState(initialUserFilterState)

  function onSearchChange(search: string) {
    setSearch(search)
  }

  function onUserFilterToggle(userId: string) {
    const userFilterNew = xor(userFilter, [userId])
    setUserFilter(userFilterNew)
  }

  return (
    <>
      <Header
        search={search}
        onSearchChange={onSearchChange}
        userFilter={userFilter}
        onUserFilterToggle={onUserFilterToggle}
      />
      <Switch>
        <Route path="/" exact>
          <RmasProvider search={search} userFilter={userFilter}>
            {({ rmas, onMoveRma }: any) => (
              <Rmas rmas={rmas} onMoveRma={onMoveRma} />
            )}
          </RmasProvider>
        </Route>
        <Route path="/rmas/add" exact>
          <RmaAddRoute />
        </Route>
        <Route path="/rmas/:id" exact>
          <RmaProvider>
            {({ rma, onUpdate, onCustomerUpdate }) => (
              <Rma
                rma={rma}
                onUpdate={onUpdate}
                onCustomerUpdate={onCustomerUpdate}
              />
            )}
          </RmaProvider>
        </Route>
      </Switch>
    </>
  )
}
