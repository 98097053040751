import styled, { css } from 'styled-components/macro'

interface IGridProps {
  margin?: boolean
  wrap?: boolean
  direction?: string
  Item?: any
}

interface IItemProps {
  size?: number
  offset?: number
  alignX?: string
}

export const Grid = styled.div<IGridProps>`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  & > * {
    padding: 0 0.5rem;
  }

  /* TODO: More elegant solution? */
  ${props =>
    props.margin &&
    css`
      margin-bottom: 1rem;
    `}

  ${props =>
    !props.wrap &&
    css`
      flex-wrap: nowrap;
    `}

  ${props =>
    props.direction === 'vertical' &&
    css`
      flex-direction: column;

      & > * {
        padding: 0.5rem 0;
      }
    `}
`

export const Item = styled.div<IItemProps>`
  ${props =>
    props.size &&
    css`
      flex-basis: ${(props.size / 12) * 100}%;
      max-width: ${(props.size / 12) * 100}%;
    `}

  ${props =>
    props.offset &&
    css`
      margin-left: ${(props.offset / 12) * 100}%;
    `}

  ${props =>
    props.alignX === 'right' &&
    css`
      align-self: flex-end;
    `}
`

export default Grid
