import { useQuery, useMutation } from '@apollo/react-hooks'

import { getRmasQuery, moveRmaMutation } from './graphql'
import { groupByStatus } from './utils'

interface IMoveRma {
  rmaId: string
  status: string
  statusCountryCode: string
  destinationRmaId: number
}

function RmasProvider({ children, search, userFilter }: any) {
  const { data, error } = useQuery(getRmasQuery, { pollInterval: 20000 })
  const [moveRma] = useMutation(moveRmaMutation)

  if (error) {
    console.error(error)
    return null
  }

  function onMoveRma(payload: IMoveRma) {
    moveRma({
      variables: { moveRmaInput: payload },
      optimisticResponse: {
        __typename: 'Mutation',
        moveRma: {
          __typename: 'Rma',
          id: payload.rmaId,
          status: payload.status || null, // this is needed for optimistic updates
          statusCountryCode: payload.statusCountryCode || null, // this is needed for optimistic updates
          destinationRmaId: payload.destinationRmaId,
        },
      },
      update: (store, { data: { moveRma } }) => {
        // TODO: Correctly position rma. Do the same as on backend.
        console.log('TODO: optimistically position rma')
      },
      refetchQueries: [{ query: getRmasQuery }],
    })
  }

  let rmas = data ? data.getRmas : []

  // Search in Rmas.
  if (search && search !== '') {
    rmas = rmas.filter((rma) => {
      search = search.toLowerCase()

      // Search for ID
      if (rma.id.includes(search)) {
        return true
      }

      // Search for ID without leading 0000
      if (rma.id.includes(parseInt(search, 10))) {
        return true
      }

      // Search for error
      if (rma.error && rma.error.includes(search)) {
        return true
      }

      // Search for customer company
      if (
        rma.customer &&
        rma.customer.company &&
        rma.customer.company.toLowerCase().includes(search)
      ) {
        return true
      }

      // Search for customer company in shipping address
      if (
        rma.customer &&
        rma.customer.shippingAddress &&
        rma.customer.shippingAddress.company &&
        rma.customer.shippingAddress.company.toLowerCase().includes(search)
      ) {
        return true
      }

      return false
    })
  }

  // Filter for users.
  if (userFilter && userFilter.length > 0) {
    rmas = rmas.filter((rma) => {
      // Always show Rmas without any user assigned.
      // if (!rma.user) {
      //   return true
      // }

      if (rma.user && userFilter.includes(rma.user.id)) {
        return true
      }

      return false
    })
  }

  // Groupy Rmas by status.
  rmas = groupByStatus(rmas)

  // Limit ARCHIVED to 10 tickets.
  if (rmas && rmas['ARCHIVED'] && rmas['ARCHIVED'].length) {
    rmas['ARCHIVED'] = rmas['ARCHIVED'].slice(0, 10)
  }

  return children({ rmas, onMoveRma })
}

export default RmasProvider
