import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const getUsersQuery = gql`
  query getUsers {
    getUsers {
      id
      firstname
      lastname
    }
  }
`

export function useProvider() {
  const { data, error } = useQuery(getUsersQuery)

  if (error) {
    console.error(error)
  }

  if (!data || !data.getUsers) {
    return { users: [] }
  }

  return { users: data.getUsers }
}
