import React, { useState } from 'react'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'

import Container from 'ui/Container'
import Button from 'ui/Button'
import Input from 'ui/Input'
import { Grid, Item } from 'ui/Grid'
import Logo from '../Logo'
import { isLoggedIn } from '../../utils/auth'
import config from '../../config'

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`

const Content = styled(Container)`
  display: flex;
  width: 680px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`

const Meta = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
  padding-top: 1.825rem;

  @media (max-width: 540px) {
    justify-content: flex-start;
    padding-right: 0;
    margin-bottom: 2rem;
  }
`

const Main = styled.div`
  flex: 1;
`

const authMutation = gql`
  mutation Auth($email: String!, $password: String!, $type: UserTypeEnum!) {
    auth(email: $email, password: $password, type: $type) {
      token
    }
  }
`

function AuthLogin() {
  const [auth, setAuth] = useState({
    email: '',
    password: '',
    type: 'ADMIN',
  })
  const [authMut, { data }] = useMutation(authMutation)
  const client = useApolloClient()

  if (isLoggedIn()) {
    return <Redirect to="/" />
  }

  function onChange(key: string, value: string) {
    setAuth({
      ...auth,
      [key]: value,
    })
  }

  function onSubmit(e: any) {
    e.preventDefault()
    authMut({
      variables: {
        email: auth.email,
        password: auth.password,
        type: auth.type,
      },
    })
  }

  if (data && data.auth && data.auth.token) {
    localStorage.setItem(config.token.key, data.auth.token)
    client.writeData({ data: { isAuth: true } })
    window.location.href = '/'
    return null
    // TODO: Otherwise, race condition
    // return <Redirect to="/" />
  }

  return (
    <Wrapper>
      <Content>
        <Meta>
          <Link to="/">
            <Logo size="large" />
          </Link>
        </Meta>
        <Main>
          <form onSubmit={onSubmit}>
            <Grid direction="vertical">
              <Item>
                <Input
                  type="text"
                  placeholder="E-Mail"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange('email', e.target.value)
                  }
                  value={auth.email}
                />
              </Item>
              <Item>
                <Input
                  type="text"
                  placeholder="Passwort"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange('password', e.target.value)
                  }
                  value={auth.password}
                />
              </Item>
              <Item alignX="right">
                <Button type="submit" size="small">
                  Login
                </Button>
              </Item>
            </Grid>
          </form>
        </Main>
      </Content>
    </Wrapper>
  )
}

export default AuthLogin
