import React from 'react'
import styled from 'styled-components/macro'

import { styles as inputStyles } from './Input'

interface IDropdown {
  items?: any
  selectedItem?: string
  onChange(e: React.ChangeEvent): void
}

const StyledSelect = styled.select`
  ${inputStyles}
  appearance: none;
  height: 44px;
  width: 100%;
  max-width: 100%;
  cursor: pointer !important; /* TODO: Why inputStyles:read-only? */
`

function Dropdown({ items = {}, selectedItem = '', onChange }: IDropdown) {
  const options: any = Object.entries(items)

  return (
    <StyledSelect value={selectedItem} onChange={onChange}>
      <option value="" disabled>
        -
      </option>
      {options.map(option => (
        <option key={option[0]} value={option[0]}>
          {option[1]}
        </option>
      ))}
    </StyledSelect>
  )
}

export default Dropdown
