import React from 'react'
import { Redirect } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'

import config from '../../config'

function AuthLogout() {
  const client = useApolloClient()

  localStorage.removeItem(config.token.key)
  client.writeData({ data: { isAuth: false } })

  return <Redirect to="/auth/login" />
}

export default AuthLogout
