import React from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'

// See: https://react-select.com/

interface IOption {
  key: string
  value: string
}

interface ISelectedOption extends IOption {}

interface ISelectProps {
  selectedOption?: ISelectedOption | null
  options: IOption[]
  placeholder?: string | null
  onChange(selectedOption: ISelectedOption): void
}

const Wrapper = styled.div`
  .ui-select__control {
    background-color: #f7f7f7;
    /* border-color: #f7f7f7; */

    &:hover {
      border-color: #cad400;
    }
  }

  .ui-select__control--is-focused {
    border-color: #cad400;
    box-shadow: none;
  }
`

export default function Select({
  options = [],
  selectedOption,
  onChange,
  placeholder = '-',
}: ISelectProps) {
  function _onChange(option, action) {
    if (onChange && typeof onChange === 'function') {
      onChange({
        key: option.value,
        value: option.label,
      })
    }
  }

  const formattedSelectedOption = selectedOption
    ? {
        value: selectedOption.key,
        label: selectedOption.value,
      }
    : null

  const formattedOptions = options.map((item) => {
    return {
      value: item.key,
      label: item.value,
    }
  })

  return (
    <Wrapper>
      <ReactSelect
        value={formattedSelectedOption}
        onChange={_onChange}
        options={formattedOptions}
        placeholder={placeholder}
        classNamePrefix="ui-select"
      />
    </Wrapper>
  )
}
