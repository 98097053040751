import React, { useState } from 'react'

import Input from 'ui/Input'
import { formatDate } from '../../../utils/date'

/** Property `event` is a string of numbers (e.g. "1575676800000") or `null` when creating a new RMA. */
function Event({ event, onChange }) {
  const [value, setValue] = useState(event ? new Date(parseInt(event)) : null)

  /** Handle change event on input. */
  function _onChange(e) {
    if (e.target.value) {
      setValue(new Date(e.target.value))
    }
  }

  /** Handle blur event (loss of focus) on input. */
  function _onBlur() {
    if (value) {
      onChange(value)
    }
  }

  return (
    <div>
      <Input
        type="date"
        value={value ? formatDate(value, 'yyyy-MM-dd') : null}
        onChange={_onChange}
        onBlur={_onBlur}
      />
    </div>
  )
}

export default Event
