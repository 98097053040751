import { useMutation } from '@apollo/react-hooks'

import { changeRmaEventMutation } from './changeRmaEventMutation'

export function useEvent(rmaId) {
  const [changeRmaEvent] = useMutation(changeRmaEventMutation)

  function onChangeEvent(date: Date) {
    changeRmaEvent({ variables: { event: { rmaId, date } } })
  }

  return {
    onChangeEvent,
  }
}
