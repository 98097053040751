import React, { useState } from 'react'

import { ICustomer } from './types'
import { CustomerShow } from './CustomerShow'
import { CustomerEdit } from './CustomerEdit'
import ButtonLink from 'ui/ButtonLink'
import Label from '../../../ui/Label'

interface ICustomerProps {
  customer?: ICustomer
  customers: ICustomer[]
  onUpdate(customer: ICustomer): void
}

function Customer({
  customer,
  customers,
  onUpdate,
}: ICustomerProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false)

  function toggleIsEditing() {
    setIsEditing(!isEditing)
  }

  function _onUpdate(customerId) {
    onUpdate(customerId)
    setIsEditing(false)
  }

  return (
    <>
      <Label>Kunde</Label>

      {isEditing ? (
        <>
          <CustomerEdit
            onUpdate={_onUpdate}
            customer={customer}
            customers={customers}
          />
          <br />
          <ButtonLink onClick={toggleIsEditing}>Abbrechen</ButtonLink>
        </>
      ) : (
        <>
          <CustomerShow customer={customer} />
          <br />
          <ButtonLink onClick={toggleIsEditing}>Bearbeiten</ButtonLink>
        </>
      )}
    </>
  )
}

export default Customer
