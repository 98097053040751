import React from 'react'

import { ICustomer } from './types'
import Select from '../../../ui/Select'
import { formatCustomerOption } from './utils'

interface ICustomerEditProps {
  customer?: ICustomer
  customers: ICustomer[]
  onUpdate(customer: ICustomer): void
}

export function CustomerEdit({
  customers,
  customer,
  onUpdate,
}: ICustomerEditProps) {
  function onChange(selectedCustomer) {
    onUpdate(selectedCustomer.key)
  }

  const options = customers.map((customer) => {
    return {
      key: customer.id,
      value: formatCustomerOption(customer),
    }
  })

  const selectedOption: any = customer
    ? options.find((option) => option.key === customer.id)
    : null

  return (
    <div>
      <Select
        options={options}
        selectedOption={selectedOption}
        onChange={onChange}
      />
    </div>
  )
}
