import { css } from 'styled-components/macro'

export default css`
  body {
    background: #fdfdfd;
    line-height: 1.4;
    color: #222;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }

  html {
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100vh;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`
