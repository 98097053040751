import React from 'react'

import Files from './Files'
import { useFilesProvider } from './useFilesProvider'

function FilesModule({ id }) {
  const { files, onUpload, onDelete } = useFilesProvider(id)

  return <Files onUpload={onUpload} files={files} onDelete={onDelete} />
}

export default FilesModule
