import React from 'react'
import styled from 'styled-components/macro'

import AddComment from './AddComment'
import Comment from './Comment'
import { IComment } from './CommentsProvider'

interface ICommentsComponent {
  comments: IComment[]
  onAdd(): void
  onUpdate(): void
  onDelete(): void
}

const Wrapper = styled.section``

const Title = styled.h3`
  margin-bottom: 1rem;

  small {
    opacity: 0.5;
    font-size: 75%;
  }
`

const Add = styled.div`
  margin-bottom: 1rem;
`

function Comments({ comments, onAdd, onUpdate, onDelete }: ICommentsComponent) {
  return (
    <Wrapper>
      <Title>
        Kommentare <small>({comments.length})</small>
      </Title>
      <Add>
        <AddComment onAdd={onAdd} />
      </Add>
      {comments.map((comment: any) => (
        <Comment
          key={comment.id}
          comment={comment}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}
    </Wrapper>
  )
}

export default Comments
