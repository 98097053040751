import gql from 'graphql-tag'

import { fileFields } from './fileFields'

export const deleteRmaFileMutation = gql`
  mutation deleteRmaFile($id: ID!) {
    deleteRmaFile(id: $id) {
      ...FileFields
    }
  }
  ${fileFields}
`
