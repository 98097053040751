import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { DragDropContext } from 'react-beautiful-dnd'

import Column from '../Board/Column'

const Wrapper = styled.div`
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 -0.75rem;
  flex: 1;
  /* background: #f7f7f7; */
`

/*
{
  "draggableId": "1",
  "type": "DEFAULT",
  "source": {
    "index": 0,
    "droppableId": "Eingang"
  },
  "reason": "DROP",
  "mode": "FLUID",
  "destination": {
    "droppableId": "In Arbeit",
    "index": 1
  },
  "combine": null
}
*/

const droppableIdMap = {
  Eingang: { status: null },
  Bestätigt: { status: 'ACCEPTED' },
  'In Arbeit (CH)': { status: 'IN_PROGRESS', statusCountryCode: 'CH' },
  'In Arbeit (AT)': { status: 'IN_PROGRESS', statusCountryCode: 'AT' },
  Versand: { status: 'SENT' },
  Archiv: { status: 'ARCHIVED' },
}

// TODO: How to make this dynamic, clean up. So logic is not spread everywhere.
function getStatus(droppableId) {
  return droppableIdMap[droppableId]
}

function getDestinationRmaId(destinationIndex, status, rmas) {
  // status.status === null actually means 'NEW' inside the front-end store.
  if (!status.status) {
    status.status = 'NEW'
  }

  const column = rmas[status.status]
  // Return early, ff there are no existing rmas in the destination column.
  if (!column) {
    return null
  }

  const destinationRma = column[destinationIndex]

  if (destinationRma) {
    if (status.status !== 'IN_PROGRESS') {
      return destinationRma.id
    }

    // Otherwise, handle statusCountryCode
    const rmasFromColumn = column.filter(
      (rma: any) => rma.statusCountryCode === status.statusCountryCode
    )

    if (!rmasFromColumn || rmasFromColumn.length <= 0) {
      return null
    }

    return rmasFromColumn[destinationIndex].id
  }

  return null
}

function Rmas({ rmas, onMoveRma }) {
  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      const status = getStatus(result.destination.droppableId)

      const move = {
        rmaId: result.draggableId,
        // position: result.destination.index,
        destinationRmaId: getDestinationRmaId(
          result.destination.index,
          { ...status },
          rmas
        ),
        ...status,
      }
      onMoveRma(move)
    },
    [onMoveRma, rmas]
  )

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Columns>
          <Column title="Eingang" rmas={rmas['NEW']} showAddButton />
          <Column title="Bestätigt" rmas={rmas['ACCEPTED']} />
          <Column title="In Arbeit" rmas={rmas['IN_PROGRESS']} split />
          <Column title="Versand" rmas={rmas['SENT']} />
          <Column title="Archiv" rmas={rmas['ARCHIVED']} />
        </Columns>
      </DragDropContext>
    </Wrapper>
  )
}

export default Rmas
