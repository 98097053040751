import React from 'react'
import styled from 'styled-components'

import Avatar from '../../Users/Avatar'
import Label from 'ui/Label'
import Dropdown from 'ui/Dropdown'
import { transformSelect, useProvider } from './utils'

interface IUser {
  id: string
  firstname: string
  lastname: string
}

interface IComponentProps {
  user: IUser
  onUpdate(id: string): void
}

const Wrapper = styled.div`
  display: flex;
`

const Item = styled.div`
  margin-right: 1rem;
`

const StyledAvatar = styled(Avatar)`
  margin-top: 1rem;
`

function User({ user, onUpdate }: IComponentProps) {
  const { users } = useProvider()
  const items = transformSelect(users)

  function onChange(e) {
    onUpdate(e.target.value)
  }

  return (
    <Wrapper>
      <Item>
        <StyledAvatar user={user} size="large" />
      </Item>
      <Item>
        <Label compact>Mitarbeiter</Label>
        <Dropdown
          items={items}
          selectedItem={user ? user.id : ''}
          onChange={onChange}
        />
      </Item>
    </Wrapper>
  )
}

export default User
