import gql from 'graphql-tag'

export const fileFields = gql`
  fragment FileFields on File {
    id
    fileName
    mimeType
    url
  }
`
