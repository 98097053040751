import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'

import { getRmasQuery, addRmaMutation } from './graphql'
import RmaAdd from './RmaAdd'

export function RmaAddRoute() {
  const [addRma, { data }] = useMutation(addRmaMutation)

  // Let's create an empty RMA to be filled out when a user navigates to the add route.
  // If it happens on accident and nothing is filled out, we offer to remove the created RMA again.
  useEffect(() => {
    addRma({
      variables: { rma: { user: { id: 1 } } },
      refetchQueries: [{ query: getRmasQuery }],
    })
  }, [addRma])

  if (!data || !data.addRma) {
    return null
  }

  const rma = data.addRma

  return <RmaAdd rma={rma} />
}
