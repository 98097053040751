import gql from 'graphql-tag'

import { rmaFields } from './rmaFields'

export const updateRmaMutation = gql`
  mutation updateRma($rma: RmaInput!) {
    updateRma(rma: $rma) {
      ...RmaFields
    }
  }
  ${rmaFields}
`
