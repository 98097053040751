import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons'

import UploadButton from './UploadButton'

interface IFiles {
  onUpload(files: FileList): void
  onDelete(id: string): void
  files: any[]
}

const Wrapper = styled.div`
  position: relative;
`

const Title = styled.h3`
  margin-bottom: 1rem;

  small {
    opacity: 0.5;
    font-size: 75%;
  }
`

const Main = styled.div`
  display: flex;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1;
`

const Item = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  display: flex;

  &:last-of-type {
    border-bottom: 0;
  }
`

const FileName = styled.a`
  text-decoration: underline;
  color: inherit;
  margin-right: 8px;
`

const FileSize = styled.span`
  opacity: 0.5;
`

const Delete = styled.button`
  margin-left: 1rem;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`

const StyledUpload = styled(UploadButton)`
  position: absolute;
  top: -12px;
  right: 0;
`

function Files({ files, onUpload, onDelete }: IFiles) {
  return (
    <Wrapper>
      <StyledUpload onUpload={onUpload}>
        <FontAwesomeIcon icon={faPlus} />
      </StyledUpload>
      <Title>
        Dateien <small>({files && files.length ? files.length : 0})</small>
      </Title>
      <Main>
        <List>
          {files &&
            files.map(file => (
              <Item key={file.id}>
                <FileName href={file.url} target="_blank">
                  {file.fileName}
                </FileName>{' '}
                <FileSize>[{file.size} KB]</FileSize>
                <Delete onClick={() => onDelete(file.id)}>
                  <DeleteIcon icon={faTrash} />
                </Delete>
              </Item>
            ))}
        </List>
      </Main>
    </Wrapper>
  )
}

export default Files
