import gql from 'graphql-tag'

export const rmaFields = gql`
  fragment RmaFields on Rma {
    id
    customer {
      id
      firstname
      lastname
      shippingAddress {
        company
        city
        countryCode
        zip
        street
      }
    }
    user {
      id
      firstname
      lastname
    }
    shippingAddress {
      company
      firstname
      lastname
      street
      zip
      city
      countryCode
    }
    reference
    serial
    article
    firmware
    error
    description
    purchaseDate
    saleDate
    creationDate
    status
    statusCountryCode
    microsoftEventDate
    fiveYearWarranty
    costEstimateRequested
  }
`
