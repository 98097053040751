import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'

import ButtonPrimary from 'ui/ButtonPrimary'

interface IUploadButtonProps {
  onUpload(files: FileList): void
  children: ReactNode
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  top: 0px;
  right: -10px;
  padding: 10px;
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -150%;
  height: 48px;
  line-height: 48px;
  font-size: 100px;
  outline: none;
`

function UploadButton({ children, onUpload, ...props }: IUploadButtonProps) {
  function onChange(e) {
    onUpload(e.target.files)
  }

  return (
    <Wrapper {...props}>
      <ButtonPrimary as="label" size="small" htmlFor="upload">
        {children}
      </ButtonPrimary>
      <Input type="file" onChange={onChange} id="upload" />
    </Wrapper>
  )
}

export default UploadButton
