import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons'

import { styles as labelStyles } from '../ui/Label'

interface ICheckbox {
  value?: boolean
  label: string
  onClick(): void
}

interface ILabel {
  type?: string
}

const Label = styled.label<ILabel>`
  cursor: pointer;

  ${props =>
    props.type === 'label' &&
    css`
      ${labelStyles}
    `}
`

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

function Checkbox({ value, onClick, label }: ICheckbox) {
  return (
    <Label onClick={onClick}>
      {value ? <Icon icon={faCheckSquare} /> : <Icon icon={faSquare} />}
      {label}
    </Label>
  )
}

export default Checkbox
