import React from 'react'
import styled from 'styled-components'

import Label from 'ui/Label'
import Dropdown from 'ui/Dropdown'

interface IError {
  errorCode: string
  errors: any[]
  onUpdate(rmaId: string): void
}

const Wrapper = styled.div``

function Error({ errorCode, errors, onUpdate }: IError) {
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    onUpdate(e.target.value)
  }

  // Prepare the dropdown items values as [KEY]: [VALUE]
  const items = Object.keys(errors).reduce((acc, key): any => {
    acc[key] = `${key}: ${errors[key]}`
    return acc
  }, {})

  return (
    <Wrapper>
      <Label>Fehlercode</Label>
      <Dropdown
        items={items}
        selectedItem={errorCode || ''}
        onChange={onChange}
      />
    </Wrapper>
  )
}

export default Error
