import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Textarea from 'ui/Textarea'

interface AddCommentProps {
  onAdd(content: string): void
}

const Form = styled.form`
  margin-bottom: 1.5rem;
`

const Area = styled.div`
  position: relative;
`

const Button = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  font-weight: bold;
  border: 0;
  padding: 0.75rem 1rem;
  cursor: pointer;
`

function AddComment({ onAdd }: AddCommentProps) {
  const [content, setContent] = useState('')

  function onKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      // TODO: Allow new lines etc.
      console.log('DEBUG: onKeyUp()')
    }
  }

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value
    setContent(value)
  }

  function onSubmit(e) {
    e.preventDefault()
    onAdd(content)
    setContent('')
  }

  return (
    <Form onSubmit={onSubmit}>
      <Area>
        <Textarea
          placeholder="Kommentar hinzufügen"
          value={content}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        <Button type="submit">Senden</Button>
      </Area>
    </Form>
  )
}

export default AddComment
