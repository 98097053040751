import React from 'react'

import { ICustomer } from './types'

interface ICustomerShowProps {
  customer?: ICustomer
}

export function CustomerShow({ customer }: ICustomerShowProps) {
  if (!customer) {
    return null
  }

  return (
    <div>
      {customer.firstname} {customer.lastname}
      <br />
      {customer.shippingAddress && (
        <>
          {customer.shippingAddress.company}
          <br />
          {customer.shippingAddress.street}
          <br />
          {customer.shippingAddress.zip} {customer.shippingAddress.city}
          <br />
          {customer.shippingAddress.countryCode}
        </>
      )}
    </div>
  )
}
