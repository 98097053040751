import styled from 'styled-components/macro'

import { styles as inputStyles } from './Input'

const Textarea = styled.textarea`
  ${inputStyles}
  height: 120px;
  width: 100%;
  max-width: 100%;
  display: block;
`

export default Textarea
