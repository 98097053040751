import gql from 'graphql-tag'

import { fileFields } from './fileFields'

export const getRmaFilesQuery = gql`
  query getRmaFiles($rmaId: ID!) {
    getRmaFiles(rmaId: $rmaId) {
      ...FileFields
    }
  }
  ${fileFields}
`
