import countries from 'i18n-iso-countries'
import { formatDistanceToNow } from 'date-fns'
import { de } from 'date-fns/locale'

countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

export function formatRmaId(id) {
  return id.padStart(4, '0')
}

export function formatAge(timestamp) {
  if (!timestamp) {
    return ''
  }

  const date = new Date(parseInt(timestamp))
  return formatDistanceToNow(date, { locale: de })
}

export function formatArticle(article = '') {
  if (!article) {
    return ''
  }

  return article.toUpperCase()
}

export function formatAddress(address) {
  if (!address || typeof address !== 'object') {
    return ''
  }

  let html = ``

  if (address.company) {
    html = `${html}${address.company}<br />`
  }

  if (address.firstname || address.lastname) {
    html = `${html}${address.firstname} ${address.lastname}<br />`
  }

  if (address.street) {
    html = `${html}${address.street} ${address.streetNo || ''}<br />`
  }

  if (address.zip || address.city) {
    html = `${html}${address.zip} ${address.city}<br />`
  }

  if (address.countryCode) {
    html = `${html}${getCountryFromCode(address.countryCode)}`
  }

  return html
}

export function getCountryFromCode(countryCode) {
  const country = countries.getName(countryCode, 'de')

  return country ? country : countryCode
}

export function groupByStatus(rmas = []) {
  return rmas.reduce((accumulator, currentValue: any) => {
    if (!currentValue.status) {
      currentValue.status = 'NEW'
    }

    if (!Array.isArray(accumulator[currentValue.status])) {
      accumulator[currentValue.status] = []
    }

    accumulator[currentValue.status].push(currentValue)

    return accumulator
  }, {})
}

export function getCustomerName(customer: any = {}) {
  if (customer.name) {
    return customer.name
  }

  if (customer.firstname || customer.lastname) {
    return `${customer.firstname} ${customer.lastname}`
  }

  return ''
}

export function getCustomerCompany(customer: any = {}): string {
  if (
    customer &&
    customer.shippingAddress &&
    customer.shippingAddress.company
  ) {
    return customer.shippingAddress.company
  }

  return `${customer.firstname} ${customer.lastname}`
}
