import React from 'react'
import styled from 'styled-components/macro'

import { formatRmaId, formatAge, getCustomerCompany } from './utils'
import { formatDate } from '../../utils/date'
import Label, { styles as labelStyles } from 'ui/Label'
import Avatar from '../Users/Avatar'

const Wrapper = styled.div``

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0.25rem 0;
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 16px;
`

const Age = styled.div`
  ${labelStyles}
  margin-left: auto;
  font-weight: normal;
`

const Items = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled.div`
  margin-right: 0.75rem;
`

function RmaPreview({ rma }) {
  const customer = rma.customer ? getCustomerCompany(rma.customer) : null

  return (
    <Wrapper>
      <Header>
        <Title>RMA {formatRmaId(rma.id)}</Title>
        <Age>{formatAge(rma.creationDate)}</Age>
      </Header>
      <Items>
        {rma.user && (
          <Item>
            <Avatar user={rma.user} />
          </Item>
        )}
        {rma.error && (
          <Item>
            <Label compact>Fehler</Label>
            {rma.error.toUpperCase()}
          </Item>
        )}
        {customer && (
          <Item>
            <Label compact>Kunde</Label>
            {customer}
          </Item>
        )}
        {rma.microsoftEventDate && (
          <Item>
            <Label compact>Termin</Label>
            {formatDate(rma.microsoftEventDate)}
          </Item>
        )}
      </Items>
    </Wrapper>
  )
}

export default RmaPreview
