import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'

import config from '../config'

export function createApolloClient() {
  const httpLink: any = createUploadLink({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(config.token.key)

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const client: any = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
