import React from 'react'
import styled, { css } from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import config from '../../../config'
import Avatar from '../Avatar'

interface IProfile {
  isActive?: boolean
}

interface IItem {
  index?: number
}

const getUsersQuery = gql`
  query getUsers {
    getUsers {
      id
      firstname
      lastname
      email
    }
  }
`

const Wrapper = styled.div`
  padding: 0 30px;
`

const Profile = styled.div<IProfile>`
  display: block;
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.25s;
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      opacity: 1;
      filter: none;
      /* border-color: ${config.colors.primary}; */
    `};
`

const Item = styled.li<IItem>`
  padding: 0 2px;
  position: relative;
  z-index: ${props => props.index};
  transition: margin 0.25s;
`

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
`

function UsersFilter({ userFilter, onUserFilterToggle }) {
  const { data, error, loading } = useQuery(getUsersQuery)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  function checkActiveStatus(id) {
    return userFilter.includes(id)
  }

  return (
    <Wrapper>
      <List>
        {data.getUsers.map((user: any, index: number) => (
          <Item key={user.id} index={data.getUsers.length - index}>
            <Profile
              onClick={() => onUserFilterToggle(user.id)}
              isActive={checkActiveStatus(user.id)}
            >
              <Avatar user={user} size="large" />
            </Profile>
          </Item>
        ))}
      </List>
    </Wrapper>
  )
}

export default UsersFilter
